import { useLoaderData } from '@remix-run/react'

import KeystonePageContent from '~/modules/keystone-pages/components/KeystonePageContent'
import type { loader } from './loader'

/*
 * Render a page from the Keystone CMS
 */
export const KeystonePage = () => {
  const data = useLoaderData<typeof loader>()

  return <KeystonePageContent title={data?.title} content={data?.content} />
}

export default KeystonePage
