import type { DocumentRendererProps } from '@keystone-6/document-renderer'
import GenericDocument from '~/components/GenericDocument'
import PageWrapper from '~/modules/layout/components/PageWrapper'

export interface KeystonePageProps {
  title?: string | null
  content?: {
    document?: DocumentRendererProps['document']
  } | null
}

const KeystonePage = ({ title, content }: KeystonePageProps) => {
  if (!title || !content) {
    return <p>missing page info</p>
  }

  return (
    <PageWrapper size="fat">
      <h1>{title}</h1>
      <div className="space-y-4">{content.document && <GenericDocument document={content.document} />}</div>
    </PageWrapper>
  )
}

export default KeystonePage
