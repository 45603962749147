import type { MetaFunction } from '@remix-run/node'
import { getLoaderCacheHeaders } from '~/utils/headers.server'

export const headers = getLoaderCacheHeaders
export { KeystonePage as default } from '~/modules/keystone-pages/pages/KeystonePage'
export { loader } from '~/modules/keystone-pages/pages/KeystonePage/loader'

export const meta: MetaFunction = ({ params }) => {
  return [
    {
      title: `${params?.page?.[0]?.toLocaleUpperCase()}${params.page?.slice(1)} | Pevan & Sarah`
    }
  ]
}
